.boxshadow {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.boxshadow-light {
  -webkit-box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.5);
}
.scroll-bar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.scroll-bar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(255, 91, 91, 0.05);
}
.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(255, 91, 91, 0.08);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#shield-iframe {
  border: none;
  width: 100%;
  height: calc(100vh - 55px);
  margin-bottom: -10px;
}
